@import "../../variables.scss";
.container {
    width: 100%;
    // height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 10px;
}

.choose_file_div {
    width: 100%;
    min-height: 35px;
    background-color: white;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 4px;
    cursor: pointer;

    label {
        font-size: 14px;
        color: $main-color;
        text-align: center;
        cursor: pointer;
    }

    .error_label {
        font-size: 12px;
        color: $red-color;
        text-align: center;
    }
}

.error {
    border: 1px solid $red-color;
}
