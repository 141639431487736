@import "../../variables.scss";

.container {
    max-width: 100%;
    margin: 4px 0;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: $white-color;
    color: $main-color;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    .label {
        color: $dark-color;
        min-width: 75px;
        font-size: 12px;
        cursor: pointer;
    }

    .value {
        margin-inline-start: 8px;
        padding: 4px 0px;
        font-size: 16px;
        outline: none;
        border: none;
        color: $main-color;
        width: 100%;
        cursor: pointer;

        &:lang(ar) {
            margin-right: 8px;
        }

        &:lang(ar) {
            margin-left: 8px;
        }
    }

    .placeholder {
        font-size: 12px;
        color: $light-color;
    }
}

.for_search {
    background: $white-color;
    color: $light-color;
    border-radius: 6px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 5px;
    border: 1px solid rgba($color: $light-grey-color, $alpha: 1);

    .label {
        font-size: 12px;
        font-weight: bold;
        width: 100px;
        color: $white-color;
        background-color: $main-color;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .label:lang(ar) {
        margin-left: 10px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        text-align: left;
    }

    .label:lang(en) {
        margin-right: 10px;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        text-align: right;
    }

    .value {
        padding: 4px 0px;
        outline: none;
        border: none;
        color: $main-color;
        flex: 1;
    }

    .value:lang(ar) {
        text-align: right;
        margin-left: 8px;
    }

    .value:lang(en) {
        text-align: left;
        margin-right: 8px;
    }
}

.without_border {
    border: 0;
}

@media only screen and (min-width: $medium) {
    .for_search {
        .label {
            font-size: 14px;
            width: 125px;
        }
    }
}

.error {
    border: 1px solid $red-color;
}

.error_label {
    font-size: 12px;
    text-align: center;
    color: $red-color;
}
