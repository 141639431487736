@import "../../variables.scss";

.actions {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-block-end: 10px;
    margin-bottom: 10px;
    gap: 6px;
    padding: 6px 0;
    padding-right: 35px;
}

@media only screen and (min-width: $small) {
    .actions {
        padding-right: 0;
    }
}
