@import "../../variables.scss";

.container {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .label {
        font-size: 16px;
        color: $main-color;
    }

    .count {
        font-size: 16px;
        color: $green-color;
        margin: 0 6px;
    }
}
