@import "../../variables.scss";

.input_div {
  min-width: 100%;
  margin: 4px 0;
  padding: 4px 4px;
  border-radius: 8px;
  color: $main-color;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: $white-color;
  border: 1px solid $main-color;
}

.input_div input {
  width: 75px;
  font-size: 14px;
  padding: 4px 0px;
  outline: none;
  border: none;
  color: $main-color;
  background-color: $white-color;
  flex: 1;

  &:lang(ar) {
    margin-right: 8px;
  }

  &:lang(en) {
    margin-left: 8px;
  }
}

.input_div input:disabled {
  background-color: $white-color;
  color: $light-color;
}

.input_div input::placeholder {
  font-weight: bold;
  font-size: 12px;
}

.bordered {
  border: 1px solid $main-color;
}

.input_div label {
  font-size: 12px;

  &:lang(ar) {
    text-align: left;
  }

  &:lang(en) {
    text-align: right;
  }
}

.input_div .icon_error {
  width: 30px;
  color: $red-color;
}

.input_div .icon_clear {
  width: 30px;
  color: $main-color;
}
