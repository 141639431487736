@import "../../variables.scss";

.container {
  max-height: 400px;
  overflow: auto;
  padding-inline: 12px;
  padding-left: 12px;
  padding-right: 12px;

  h3 {
    font-size: 20px;
    color: $main-color;
    text-decoration: underline;
  }

  strong {
    font-size: 16px;
    font-weight: bold;
    color: $green-color;
  }

  ul.first {
    list-style-type: square;
    list-style-position: inside;
    list-style-image: none;
  }

  ul.second {
    list-style-type: circle;
    list-style-position: inside;
    list-style-image: none;

    &:lang(ar) {
      margin-right: 25px;
    }

    &:lang(en) {
      margin-left: 25px;
    }
  }

  p,
  li {
    text-align: justify;
  }

  p.strong {
    font-weight: bold;
    color: $dark-color;
    text-decoration: underline;
  }

  button {
    display: block;
    padding: 10px 20px;
    margin: 0 auto;
    background: transparent;
    color: $green-color;
    border: 1px solid $green-color;
    font-weight: bold;
    outline: none;
    border-radius: 6px;
    transition: all 0.3s;
    cursor: pointer;
    margin-block-start: 10px;
    margin-top: 10px;

    &:hover {
      background: $green-color;
      color: $white-color;
    }
  }
}
