@import '../../variables.scss';

.container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $main-color;

  h1 {
    font-family: 'Great Vibes', cursive;
    font-size: 3rem;
    font-weight: 700;
    background: linear-gradient(to right, $offer-color 10%, rgba($color: $offer-color, $alpha: 0.3) 50%, $offer-color 60%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    background-clip: text;
    color: #fff;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 3s linear infinite;
    display: inline-block;
  }

  @keyframes textclip {
    to {
      background-position: 200% center;
    }
  }
}

@media only screen and (min-width: $small) {
  .container {
    h1 {
      font-size: 5rem;
    }
  }
}