.container {
    min-width: 100%;
    position: relative;
}

.container.slide_left {
    animation-name: slideLeft;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
}

@keyframes slideLeft {
    0% {
        right: -400px;
    }

    100% {
        right: 0;
    }
}

.container.slide_right {
    animation-name: slideRight;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
}

@keyframes slideRight {
    0% {
        left: -400px;
    }

    100% {
        left: 0;
    }
}
