@import "../../variables.scss";

.container {
    background: $white-color;
    border-radius: 6px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 5px;

    label {
        font-size: 12px;
        font-weight: bold;
        min-width: 100px;
        color: $white-color !important;
        background-color: $main-color;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    label:lang(ar) {
        margin-left: 10px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        text-align: left;
    }

    label:lang(en) {
        margin-right: 10px;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        text-align: right;
    }

    input[type="text"],
    input[type="date"] {
        padding: 4px 0px;
        outline: none;
        border: none;
        color: $main-color;
        width: 100%;
    }

    input[type="text"]:lang(ar),
    input[type="date"]:lang(ar) {
        margin-left: 8px;
        text-align: right;
    }

    input[type="text"]:lang(en),
    input[type="date"]:lang(en) {
        margin-right: 8px;
        text-align: left;
    }

    input[type="text"]::placeholder {
        color: #000;
        font-size: 10px;
    }

    span {
        position: absolute;
        color: $light-color;
        align-self: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    span:lang(ar) {
        left: 10px;
    }

    span:lang(en) {
        right: 10px;
    }
}

.border {
    border: 1px solid rgba($color: $light-grey-color, $alpha: 1);
}

@media only screen and (min-width: $medium) {
    .container {
        label {
            font-size: 14px;
            min-width: 125px;
        }
    }
}
