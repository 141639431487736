/* Extra small devices (phones, 600px and down) */
// @media only screen and (max-width: 320px)
$xsmall: 320px;
/* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 481px)
$small: 481px;
/* Medium devices (landscape tablets, 769px and up) */
// @media only screen and (min-width: 769px)
$medium: 769px;
/* Large devices (laptops/desktops, 1025px and up) */
// @media only screen and (min-width: 1025px)
$large: 1025px;
/* Extra large devices (large laptops and desktops, 1201px and up) */
// @media only screen and (min-width: 1210px)
$xlarge: 1201px;

/* FONTS */
$font-ar-01: "Noto Naskh Arabic", serif;
$font-ar-02: "Lateef", cursive;
$font-ar-03: "Tajawal", sans-serif;
$font-ar-04: "Cairo", sans-serif;

h2.small_font {
  font-size: 1.1rem;
}

/* SCSS HEX */
$main-color: #566092;
$dark-color: #293664;
$light-color: #858dc3;
$backup-color: #99cf16;
$red-color: #ff787e;
$white-color: #ffffffff;
$green-color: #2aa70b;
$blue-color: #366feb;
$yellow-color: #e9b93f;
$offer-color: #aae3ff;
$light-grey-color: #e3e3e3;
