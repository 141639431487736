.container {
    width: 50%;
    height: 200px;
    margin: 50px auto;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
