@import "../../variables.scss";
// style for icon div
.icon {
    width: fit-content;
    min-width: 32px;
    min-height: 32px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    margin: 0 auto;

    label {
        font-size: 14px;
        color: $main-color;
        margin: 0 4px;
        cursor: pointer;
    }
}

.icon:hover {
    border-radius: 50%;
    background: rgba($color: #000000, $alpha: 0.1) !important;
    box-shadow: inset 2px 2px 2px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}

.tooltip {
    display: none;
    min-width: 100px;
    font-size: 0.7rem;
    padding: 2px 8px;
    background-color: rgba($color: #000000, $alpha: 0.8);
    color: $white-color;
    border-radius: 6px;
    position: absolute;
    top: 35px;
    z-index: 50;
}

.icon:hover .tooltip {
    display: flex;
    justify-content: center;
    align-items: center;
}

.red_circle {
    width: 12px;
    height: 12px;
    background-color: $red-color;
    border-radius: 50%;
    position: absolute;
    top: -5px;

    &:lang(ar) {
        left: -5px;
    }

    &:lang(en) {
        right: -5px;
    }
}

.close_to_icon:lang(ar) {
    top: 0;
    left: 0;
}

.close_to_icon:lang(en) {
    top: 0;
    right: 0;
}
