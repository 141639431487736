@import "../../variables.scss";

.full_size {
  width: 100vw;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.6);
  z-index: 200;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
}

.full_size button {
  margin-block-start: 50px;
  margin-top: 50px;
}

.msg {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: $white-color;
}