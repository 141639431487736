@import "../../variables.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-shadow: 0 0 5px 3px rgba($color: $main-color, $alpha: 0.4);
  border-radius: 12px;
  background-color: #606ba0;

  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }

  .signup {
    color: $white-color;
    text-align: center;
    cursor: pointer;

    .signup_button {
      display: inline-block;
      color: $red-color;
      font-weight: bold;
      cursor: pointer;
    }
  }

  h3 {
    flex: 1;
    color: $white-color;
    font-weight: bold;
    font-size: 24px;
    text-shadow: 2px 2px 5px#000;
  }

  .remember_me {
    margin-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      display: inline-block;
      margin-inline: 4px;
      margin-left: 4px;
      margin-right: 4px;
      color: $white-color;
    }
  }

  .error {
    color: $red-color;
    font-size: 12px;
    font-weight: bold;
  }

  .forget_password {
    color: $red-color;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.img {
  width: 75px;
  height: auto;
  background: rgba($color: $main-color, $alpha: 1);
  border-radius: 50%;
  box-shadow: 0 0 15px 5px rgba($color: $offer-color, $alpha: 1);
}

.bottom_container {
  margin-top: 15px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 20px 5px rgba($color: $main-color, $alpha: 1);
  }
}

.outer_container {
  order: 2;
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  animation-name: signInContainerAnim;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

@media only screen and (min-width: $small) {
  .outer_container {
    width: 400px;
    justify-content: space-around;
  }
}

@media only screen and (min-width: $medium) {
  .outer_container {
    width: 400px;
    order: 1;
  }
}

@keyframes signInContainerAnim {
  0% {
    opacity: 0;
    left: -100vw;
  }

  70% {
    opacity: 1;
    left: 0;
    transform: scale(1);
  }

  75% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
