@import "../../variables.scss";

.search_container {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 12px;
}

.search_div {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 5px;
  background: $white-color;
}

.search_div.has_value {
  border-bottom: 1px solid $white-color;
  border-radius: 12px 12px 0 0;
}

.input {
  background-color: transparent;
  flex: 1;
  border: none;
  outline: none;
  color: $main-color;
  border-bottom: 2px solid $dark-color;
}

.result_div {
  max-height: 400px;
  background-color: rgba($color: $white-color, $alpha: 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: scroll;
}

.msg {
  color: $green-color;
  text-align: center;
  font-weight: bold;
}
