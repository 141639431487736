@import "../../variables.scss";

.item_row {
  width: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  position: relative;

  .first_row {
    display: flex;
    justify-content: flex-start;

    .icon {
      background-color: $main-color;
      color: $white-color;
      display: inline-block;
      display: flex;
      border-radius: 50%;
      cursor: pointer;

      &:lang(ar) {
        margin-left: 10px;
      }

      &:lang(en) {
        margin-right: 10px;
      }
    }

    .item_names_container {
      flex: 1;
      color: $main-color;
      font-size: 16px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  .second_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding-right: 30px;
  }
}
