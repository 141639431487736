@import "../../variables.scss";

.input_div {
  max-width: 100%;
  position: relative;
  margin: 4px 0;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: $white-color;
  color: $main-color;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  label {
    color: $dark-color;
    min-width: 80px;
    font-size: 12px;
  }

  .input {
    padding: 4px 0px;
    font-size: 16px;
    outline: none;
    border: none;
    color: $main-color;
    width: 100%;

    &:lang(ar) {
      margin-right: 8px;
    }

    &:lang(en) {
      margin-left: 8px;
    }

    &:disabled {
      background-color: transparent;
      color: $dark-color;
    }

    &::placeholder {
      font-size: 12px;
      color: $light-color;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
}

.bordered {
  border: 1px solid $light-color;
}

.input_div .icon_error {
  width: 30px;
  color: $red-color;
}

.input_div .icon_close {
  width: 30px;
  color: $light-color;
}

.error_label {
  font-size: 12px;
  color: $red-color;
  text-align: center;
}
