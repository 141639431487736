@import "../../variables.scss";

.container {
    text-align: center;
    margin-bottom: 15px;
    order: 1;
    width: 225px;
    height: 70px;
    background-blend-mode: multiply;
    position: relative;

    h2 {
        font-size: 36px;
        font-weight: bold;
        color: $main-color;
        text-shadow: 2px 2px 3px #00000086;
        font-family: "Great Vibes", cursive;
        display: block;
    }

    p {
        font-size: 24px;
        color: $white-color;
        text-shadow: 2px 2px 5px#000;
        font-family: "Dancing Script", cursive;
    }
}

.lang_div {
    border-radius: 4px;
    overflow: hidden;
    position: fixed;
    top: 10px;
    // left: 60px;

    &:lang(ar) {
        left: 60px;
    }

    &:lang(en) {
        right: 60px;
    }

    button {
        border: none;
        outline: none;
        padding: 5px 12px;
        cursor: pointer;
    }
}

@media only screen and (min-width: $medium) {
    .container {
        background-image: url("../../assets/logo_with_slogan.jpg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        order: 2;
        width: 400px;
        height: 267px;

        h2 {
            display: none;
            font-size: 48px;
        }

        p {
            font-size: 28px;
        }
    }
}
