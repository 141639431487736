@import "../../variables.scss";

.max_qty_div {
  background: $white-color;
  border-radius: 6px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 5px;
  border: 1px solid rgba($color: $light-grey-color, $alpha: 1);
}

.max_qty_div label:first-child {
  font-size: 12px;
  font-weight: bold;
  min-width: 100px;
  color: $white-color !important;
  background-color: $main-color;
  display: flex;
  align-items: center;
  justify-content: center;

  &:lang(ar) {
    text-align: left;
    margin-left: 10px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &:lang(en) {
    text-align: right;
    margin-right: 10px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}

.max_qty_div label:nth-child(2) {
  color: $main-color;

  &:lang(ar) {
    margin-right: 10px;
  }

  &:lang(en) {
    margin-left: 10px;
  }
}

@media only screen and (min-width: $medium) {
  .max_qty_div label:first-child {
    font-size: 14px;
    min-width: 125px;
  }
}

.error {
  display: block;
  text-align: center;
  color: $red-color;
  font-size: 12px;
  font-weight: bold;
}
