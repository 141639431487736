@import "../../variables.scss";

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 10px;

    label {
        padding: 5px;
        border-radius: 6px;
        cursor: pointer;
        margin-bottom: 10px;
    }

    p {
        margin-bottom: 10px;
    }
}
