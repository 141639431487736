@import "../../variables.scss";

.side_nav_container {
  position: fixed;
  top: 60px;
  bottom: 0;
  // right: 0 !important;
  transition: width 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9;
  overflow: scroll;
}

.side_nav_container:lang(ar) {
  right: 0 !important;
}

.side_nav_container:lang(en) {
  left: 0 !important;
}

.profile_img {
  width: 100%;
  min-height: 170px;
  margin-block-end: 10px;
  margin-bottom: 10px;
  overflow: hidden;
}

.profile_img > img {
  width: 100px;
  height: 100px;
  padding: 10px;
  border-radius: 15px;
}

.profile_img h3 {
  font-size: 16px;
  color: $white-color;
  margin-block: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.profile_img p {
  font-size: 14px;
  color: $light-grey-color;
  margin-block: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.collapsed {
  width: 35px;
  background: $main-color;
  overflow: initial;
}

.showed {
  width: 100%;
  background: $main-color;
  box-shadow: -3px 0 3px rgba(0, 0, 0, 0.3);
  overflow: scroll;
  z-index: 13;
}

@media only screen and (min-width: $small) {
  .showed {
    width: 250px;
    z-index: 11;
  }
}

.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  height: calc(100vh - 170px);
  text-align: center;
}

.close_icon {
  position: absolute;
  top: 0;
  color: $white-color;
  display: block;
}

.close_icon:lang(ar) {
  left: 0;
}

.close_icon:lang(en) {
  right: 0;
}

.close_icon:hover {
  color: $red-color;
}

.nav {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.nav_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-block: auto;
  margin-top: auto;
  margin-bottom: auto;
  margin-inline-end: 10px;
  margin-left: 10px;
  position: relative;
}

.tooltip {
  display: none;
  position: absolute;
  right: 25px;
  top: 5px;
  background: black;
  padding-inline: 4px;
  padding-right: 4px;
  padding-left: 4px;
  border-radius: 4px;
  font-size: 0.7rem;
}

.nav_icon:hover .tooltip {
  display: block;
}
