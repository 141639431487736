@import "../../variables.scss";

.container {
  width: 100vw;
  height: 100vh;
}

.info {
  width: 70%;
  height: 50%;

  border-radius: 15px;

  position: relative;

  overflow: hidden;

  flex-direction: column;

  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.5);
}

.info p {
  font-size: 16px;

  color: $white-color;

  margin: 8px;

  z-index: 10;

  text-align: center;
}

p.strong {
  color: $red-color;

  font-size: 24px;

  text-align: center;
}

.info::after {
  content: "";
  width: 100%;
  height: 100%;

  background-color: $main-color;
  opacity: 0.9;

  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (min-width: $medium) {
  p.strong {
    font-size: 36px;
  }

  .info p {
    font-size: 24px;
  }
}
