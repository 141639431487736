@import "../../variables.scss";

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content_area {
  position: relative;
  flex: 1;
  z-index: 1;
  min-height: calc(100vh - 106px);
}

.toTop {
  position: fixed;
  bottom: 10px;
  color: $blue-color;
  opacity: 0.8;
  cursor: pointer;
  transition: all 0.3s;
  z-index: 100;
  background-color: $white-color;
  border-radius: 50%;

  &:lang(ar) {
    left: 10px;
  }

  &:lang(en) {
    right: 10px;
  }
}

.toTop:hover {
  opacity: 1;
  transform: scale(1.1);
}
