@import "../../variables.scss";

.toast_container {
  padding: 10px 20px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #81bbf5;

  position: fixed;
  top: 120px;

  border-radius: 15px;
  box-shadow: 2px 2px 5px rgb(84, 83, 83);

  animation-name: toastMove;
  animation-duration: 0.1s;
  animation-fill-mode: forwards;

  max-width: 300px;
  width: 300px;

  overflow: hidden;

  cursor: pointer;

  z-index: 40;

  @keyframes toastMove {
    0% {
      left: -100px;
    }

    90% {
      left: 40px;
    }

    100% {
      left: 30px;
    }
  }
}

.header {
  font-size: 12px;
  font-weight: bold;
  color: $main-color;
}

.description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
  color: $white-color;
}

.logo {
  width: 40px;
  height: 40px;

  margin-inline-end: 12px;
  margin-left: 12px;
}