@import "../../variables.scss";

.footer_container {
  background-color: $main-color;
  color: $white-color;
  margin-block-start: auto;
  margin-top: auto;
  width: 100vw;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
  // z-index: 10;
}

.row {
  display: flex;
  justify-content: space-between;
}

.label {
  flex: 1;
  text-align: end;
  min-width: 150px;
  padding: 0 12px;
}

.value {
  min-width: 150px;
  flex: 1;
  padding: 0 12px;
  display: flex;
  justify-content: flex-start;
}

.communication_info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  cursor: pointer;
  margin-inline: 4px;
  margin-left: 4px;
  margin-right: 4px;
}
