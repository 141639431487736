@import "../../variables.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-shadow: 0 0 5px 3px rgba($color: #000, $alpha: 0.4);
  border-radius: 12px;

  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }

  .signup {
    cursor: pointer;

    label {
      cursor: pointer;
    }
  }

  h3 {
    flex: 1;
    color: $white-color;
    font-weight: bold;
    font-size: 24px;
    text-shadow: 2px 2px 5px#000;
  }
}

.img {
  width: 75px;
  height: auto;
  background: rgba($color: $main-color, $alpha: 1);
  border-radius: 50%;
  box-shadow: 0 0 15px 5px rgba($color: $offer-color, $alpha: 1);
}

.bottom_container {
  margin-top: 15px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 20px 5px rgba($color: $main-color, $alpha: 1);
  }
}

.outer_container {
  order: 2;
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  animation-name: signInContainerAnim;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.stage_content_container {
  width: 100%;
  min-height: 200px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  position: relative;
}

.final_stage_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: flex-start;
  align-items: flex-start;

  button.create_account_btn {
    align-self: center;
    width: 80%;
    height: 50px;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: $offer-color;
    color: $white-color;
    border-radius: 6px;
    font-size: 16px;
    font-weight: bold;
    color: $main-color;
    flex: 1;
    box-shadow: 0 0 px 3px rgba($color: $offer-color, $alpha: 0.5);
    text-shadow: 1px 1px 3px rgba($color: $main-color, $alpha: 0.6);

    &:hover {
      box-shadow: 0 0 3px 3px rgba($color: $offer-color, $alpha: 1);
    }
  }
}

@media only screen and (min-width: $small) {
  .outer_container {
    width: 400px;
    justify-content: space-around;
  }
}

@media only screen and (min-width: $medium) {
  .outer_container {
    width: 400px;
    order: 1;
  }
}

@keyframes signInContainerAnim {
  0% {
    opacity: 0;
    left: -100vw;
  }

  70% {
    opacity: 1;
    left: 0;
    transform: scale(1);
  }

  75% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
