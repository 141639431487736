@import "../../variables.scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 125;
}

.closable_div {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal_content {
  width: 80%;
  max-width: 80%;
  position: relative;
  overflow: scroll;
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.6);
  animation-name: modal-show;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  padding: 10px;
}

.modal_header {
  padding: 8px 12px;
  color: $main-color;
  font-size: 22px;
  text-decoration: underline;
  font-weight: bold;
  text-align: center;
  border-radius: 6px 6px 0 0;
}

.modal_body {
  flex: 1;
  padding: 8px 12px;
  max-height: 400px;
  overflow: scroll;
}

.modal_footer {
  display: flex;
  justify-content: flex-end;
  padding: 4px 4px;
}

.btn {
  border: none;
  outline: none;
  color: $white-color;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  font-size: 14px;
  margin: 0 4px;
  min-width: 80px;
}

.cancel_button {
  background: $red-color;
}

.ok_button {
  background: $green-color;
}

.btn:hover {
  box-shadow: 0 0 3px 3px rgba($color: #000, $alpha: 0.3);
}

@keyframes modal-show {
  0% {
    top: -100vh;
  }

  90% {
    top: 10px;
  }

  100% {
    top: 0;
  }
}

@media only screen and (min-width: $medium) {
  .modal_content {
    width: 75%;
  }

  .modal_content.small_content {
    width: 50%;
  }
}
