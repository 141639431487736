@import "../../variables.scss";

.nav {
  width: 100%;
  min-height: 60px;
  margin-block-end: auto;
  margin-bottom: auto;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: $main-color;
  color: $white-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.left {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 60px;

  & .selectedOption {
    flex: 1;
  }

  & .link {
    margin-left: 5px;
    margin-right: 5px;
  }

  & .link:hover,
  & .link.selected {
    color: $red-color;
  }

  & .hamburger_menu_icon {
    cursor: pointer;
  }
}

.search_icon {
  color: $white-color;
  position: relative;
  margin-inline-end: 4px;
  margin-left: 4px;
  font-size: bold;

  &:hover {
    color: $red-color;
  }
}

div.center {
  display: flex;
  flex-direction: column;
}

div.center.hide {
  display: none;
}

div .link {
  font-size: 16px;
  background-color: $main-color;
  color: $white-color;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.1s ease;
}

div.center .link:hover,
div.center .link.selected {
  color: $red-color;
}

.hide {
  display: none;
}

.show {
  display: flex;
}

.search_container {
  display: none;
}

.search_container_fixed {
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  height: 60px;
  align-items: center;
  background-color: $dark-color;
  padding-inline-start: 40px;
  padding-right: 40px;
  animation-name: move_search_container;
  animation-duration: 0.1s;
  animation-fill-mode: forwards;
}

@keyframes move_search_container {
  0% {
    left: 100vw;
  }

  100% {
    left: 0;
  }
}

.back {
  position: absolute;

  &:lang(ar) {
    left: 10px;
  }
  &:lang(ar) {
    right: 10px;
  }
}

@media only screen and (min-width: $small) {
  .nav {
    display: flex;
    flex-direction: row;
    position: sticky;
    top: 0;
    z-index: 10;
  }

  div.center {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    flex: 1;
    order: 1;

    & .link {
      padding: 4px 8px;
      margin: 0 6px;
      font-size: 13px;
    }

    & .link:hover,
    & .link.selected {
      background-color: $red-color;
      color: $white-color;
      border-radius: 6px;
    }
  }

  div.left {
    order: 2;

    & .selectedOption {
      display: none;
    }

    & .hamburger_menu_icon {
      display: none;
    }

    & .link:hover,
    & .link.selected {
      color: $red-color;
    }
  }

  div.center .search_icon_div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (min-width: $medium) {
  div.center {
    & .link {
      font-size: 16px;
    }
  }
}
