@import "../../variables.scss";

.details_row {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .label {
        font-size: 12px;
        color: $light-color;
        width: 70px;
        text-decoration: underline;
    }

    .large_width {
        width: 150px;
    }

    .value {
        font-size: 14px;
        color: $main-color;
        flex: 1;

        &:lang(ar) {
            margin-right: 10px;
        }

        &:lang(en) {
            margin-left: 10px;
        }
    }
}

.filter_result {
    background-color: yellow;
    color: red !important;
    border-radius: 4px;
}

@media only screen and (min-width: $small) {
    .details_row {
        .label {
            font-size: 14px;
            width: 100px;
        }
        .large_width {
            width: 190px;
        }

        .value {
            font-size: 16px;
        }
    }
}

@media only screen and (min-width: $medium) {
    .details_row {
        .label {
            font-size: 14px;
            width: 100px;
        }

        .large_width {
            width: 190px;
        }

        .value {
            font-size: 18px;
        }
    }
}
